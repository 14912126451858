import React from 'react';

import StaticCarousel from '../StaticCarousel';
import { StaticCarouselComponent as StaticCarouselComponentType } from '@AuroraTypes';

export const StaticCarouselComponent: React.FC<StaticCarouselComponentType> = ({
  staticCarousel,
}) => (
  <StaticCarousel
    {...staticCarousel}
    id=""
  />
);
